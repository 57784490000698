import React, { Component } from "react";

class AboutTwo extends Component {
  render() {
    let title = "Nosotros",
      description =
        "Amamos a Jesús y lo seguimos. En Full Life conocerás más a Dios, experimentarás Su amor y Su poder. También encontrarás una familia y podrás escuchar prácticos mensajes que enriquecerán tu vida, alimentarán tu espíritu y sanarán tu corazón.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/pastores.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Visión</h3>
                        <p>
                          Reflejar la huella de Jesús en todas las áreas de
                          nuestra vida: ESPIRITUAL, RELACIONAL, FÍSICA,
                          PROFESIONAL Y MINISTERIAL; impactando nuestra familia,
                          sociedad y los lugares de mayor necesidad.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Misión</h3>
                        <p>
                          Viviendo por principios y no por emociones: Guiados
                          por la palabra de Dios, empoderados por el Espíritu
                          Santo y hablando el amor de Jesús en todo lo que
                          hacemos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="view-more-btn mt--60 text-center">
                      <a
                        className="rn-button-style--2 btn-solid"
                        href="/conocenos"
                      >
                        <span>Conoce más</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
